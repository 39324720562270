/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Backdrop, Box, Typography } from '@mui/material';

import { differenceInDays, getWeekOfMonth, isToday } from 'date-fns';

import { useAuth } from '../../context/AuthProvider';

export default function ModalCartaoAniversariante() {
  const auth = useAuth();
  const navigate = useNavigate();

  const today = useMemo(() => new Date(), []);

  const keyInLocalStorage = `@portaldoconhecimento.displayModalAniversariante.${today.getFullYear()}`;

  const [showModal, setShowModal] = useState(false);

  const [displayModal, setDisplayModal] = useState(() => {
    const displayModalInLocalStorage = localStorage.getItem(keyInLocalStorage);

    if (displayModalInLocalStorage == null) {
      return true;
    }

    return displayModalInLocalStorage === 'true';
  });

  const handleClose = () => {
    setShowModal(false);
    setDisplayModal(false);

    localStorage.setItem(keyInLocalStorage, 'false');
  };

  const [cartaoAniversarioSrc, setCartaoAniversarioSrc] = useState(
    '/aniversarios/default/01.jpg',
  );

  const [showNome, setShowNome] = useState(false);

  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    imageRef.current?.addEventListener('load', () => {
      setShowNome(true);
    });
  }, []);

  useEffect(() => {
    if (auth.servidor) {
      try {
        const [, mes, dia] = auth.servidor.data_nascimento
          .split('-')
          .map(v => parseInt(v, 10));

        const dateAniversario = new Date(
          today.getFullYear(),
          mes - 1,
          dia,
          0,
          0,
          0,
        );

        const filePath = `/aniversarios/${today.getFullYear()}/${(
          dateAniversario.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}/${getWeekOfMonth(dateAniversario)
          .toString()
          .padStart(2, '0')}.jpg`;

        const file = new XMLHttpRequest();
        file.open('HEAD', filePath, false);
        file.send();

        if (file.status === 200) {
          setCartaoAniversarioSrc(filePath);
        }

        if (
          isToday(dateAniversario) ||
          (differenceInDays(today, dateAniversario) > 0 &&
            differenceInDays(today, dateAniversario) <= 7)
        ) {
          if (displayModal) setShowModal(true);
        }
      } catch {
        localStorage.removeItem(keyInLocalStorage);
        navigate('/');
      }
    }
  }, [auth.servidor, displayModal, keyInLocalStorage, navigate, today]);

  return (
    <Backdrop
      open={showModal}
      onClick={handleClose}
      sx={{
        color: 'white',
        bgcolor: 'rgba(0, 0, 0, .8)',
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <Box position="relative">
        <Box
          ref={imageRef}
          component="img"
          src={cartaoAniversarioSrc}
          sx={{
            maxWidth: { xs: 350, sm: 550, md: 800 },
            height: 'auto',
          }}
        />

        <Box
          sx={{
            position: 'absolute',
            top: { xs: 20, sm: 35, md: 50 },
            left: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            color="#FFFFFF"
            component="p"
            textTransform="capitalize"
            lineHeight={1.15}
            fontWeight="bold"
            sx={{
              maxWidth: '70%',
              fontFamily: 'Fredoka One',
              fontSize: {
                xs: 14,
                sm: 20,
                md: 27,
              },
              textAlign: 'center',
              display: showNome ? 'block' : 'none',
              px: 1,
              py: 2,
              borderRadius: 4,
            }}
          >
            CARO(a) {auth.servidor?.nome_social || auth.servidor?.nome}
          </Typography>
        </Box>
      </Box>
    </Backdrop>
  );
}
